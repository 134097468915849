import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import AdventureAdminCommon from '@adventureinc/common-admin-front'
import helperPlugin from './plugin/helpers'
import './assets/css/common.css'

Vue.use(VueCompositionAPI)
Vue.use(AdventureAdminCommon)
Vue.use(helperPlugin)

import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
}).$mount('#app')
