import { getBgColor, getTableColumnColor } from '../util/util'
// import { App } from 'vue'

export type InjectType = {
  getBgColor: ReturnType<typeof getBgColor>
  getTableColumnColor: ReturnType<typeof getTableColumnColor>
}

const helpers = {
  getBgColor,
  getTableColumnColor,
}

const helperPlugin = {
  install(vue: any) {
    vue.prototype.$helpers = helpers
  },
}

export default helperPlugin
