type TypeRow<T> = {
  [key: string]: string | number | T
}

export const getBgColor = (mode: string, code: number): string => {
  if (mode === 'none') {
    return ''
  } else if (mode === 'contractStatus') {
    if (code === 1 || code === 2) {
      return 'bg-green_ccf'
    } else if (code === 4 || code === 7) {
      return 'bg-red_ffc'
    } else if (code === 6) {
      return 'bg-yellow_fff'
    } else {
      return ''
    }
  } else if (mode === 'examinationStatus') {
    if (code === 1) {
      return 'bg-purple_e5c'
    } else if (code === 2) {
      return 'bg-green_ccf'
    } else if (code === 3) {
      return 'bg-orange_ffe'
    } else if (code === 4) {
      return 'bg-yellow_fff'
    } else if (code === 6) {
      return 'bg-red_ffc'
    } else {
      return ''
    }
  } else if (mode === 'sendStatus') {
    if (code === 2) {
      return 'bg-red_ffc'
    } else if (code === 4) {
      return 'bg-gray_ccc'
    } else {
      return ''
    }
  } else if (mode === 'requiredFilesUpdateStatus') {
    if (code === 2) {
      return 'bg-yellow_fff'
    } else {
      return ''
    }
  }
  return ''
}

export const getTableColumnColor = (
  mode: string,
  row: TypeRow<any>
): string => {
  let classStr = ''

  if (mode === 'none') {
    return classStr
  } else if (mode === 'contractStatus') {
    const contractStatusId = row.contractStatusId || 1

    if (contractStatusId === 1 || contractStatusId === 2) {
      classStr += ' bg-green_ccf'
    } else if (contractStatusId === 4 || contractStatusId === 7) {
      classStr += ' bg-red_ffc'
    } else if (contractStatusId === 6) {
      classStr += ' bg-yellow_fff'
    }
  } else if (mode === 'examinationStatus') {
    const examinationStatusId = row.examinationStatusId
    if (examinationStatusId === 1) {
      classStr += ' bg-purple_e5c'
    } else if (examinationStatusId === 2) {
      classStr += ' bg-green_ccf'
    } else if (examinationStatusId === 3) {
      classStr += ' bg-orange_ffe'
    } else if (examinationStatusId === 4) {
      classStr += ' bg-yellow_fff'
    } else if (examinationStatusId === 6) {
      classStr += ' bg-red_ffc'
    }
  } else if (mode === 'requiredFilesUpdateStatus') {
    const requiredFilesUpdateStatusId =
      row.latestAttachmentFilesUpdate.requiredFilesUpdateStatusId
    if (requiredFilesUpdateStatusId === 2) {
      classStr += ' bg-yellow_fff'
    }
  } else if (mode === 'sendStatus') {
    const sendStatusId = row.sendStatusId
    if (sendStatusId === 2) {
      classStr += ' bg-red_ffc'
    } else if (sendStatusId === 4) {
      classStr += ' bg-gray_ccc'
    }
  }

  return classStr
}
