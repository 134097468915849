import { Option } from '@adventureinc/common-admin-front/src/types/common'

export const LANGUAGE_LIST: Option[] = [
  {
    value: 'ja',
    text: '日本語',
  },
  {
    value: 'en',
    text: 'English',
  },
  {
    value: 'ko',
    text: '한국어',
  },
]
