








































import Vue from 'vue'
import Sample from '@/components/Test.vue'
import { LANGUAGE_LIST } from '@/util/const'

export default Vue.extend({
  name: 'App',
  components: {
    Sample,
  },
  data() {
    return {
      menuList: [
        {
          index: 1,
          title: 'TOP',
          link: '#test',
        },
        {
          index: 2,
          title: 'ボタン',
          link: '#sample-button',
          display: 'WARNING',
        },
        {
          index: 3,
          title: 'フォームアイテム',
          subMenu: [
            {
              index: 1,
              title: 'テキストエリア',
              link: '#sample-textarea',
              display: 'HIDE',
            },
            {
              index: 2,
              title: 'チェックボックス',
              link: '#sample-checkbox',
            },
            {
              index: 3,
              title: 'ラジオボタン',
              link: '#sample-radio',
            },
            {
              index: 4,
              title: 'セレクトボックス',
              link: '#sample-select',
            },
          ],
        },
        {
          index: 4,
          title: 'その他',
          display: 'SHOW',
          subMenu: [
            {
              index: 1,
              title: 'ファイル操作',
              link: '#sample-file',
            },
            {
              index: 2,
              title: 'カードボタン',
              link: '#sample-card',
            },
            {
              index: 3,
              title: 'モーダル関連',
              link: '#sample-modal',
            },
            {
              index: 4,
              title: 'ローディング関連',
              link: '#sample-loading',
            },
          ],
        },
        {
          index: 5,
          title: 'ページネーション',
          link: '#sample-pager',
        },
        {
          index: 6,
          title: 'テーブル',
          link: '#sample-table',
        },
      ],
      selectedMenu: 1,
      selectedSubMenu: 0,
      isOpenSideMenu: true,
      languageList: LANGUAGE_LIST,
      selectedLocale: 'ja',
    }
  },

  mounted() {
    console.log(this)
  },

  updated() {
    console.log(this.selectedLocale)
  },

  methods: {
    transitionMenu(link: string, index: number, subIndex?: number) {
      this.menuSelect(index, subIndex)
      const table = document.querySelector(link)
      const targetOffsetTop =
        window.scrollY + table!.getBoundingClientRect().top
      window.scrollTo({
        top: targetOffsetTop,
        behavior: 'smooth',
      })
    },
    menuSelect(index: number, subIndex?: number) {
      this.selectedMenu = index
      this.selectedSubMenu = subIndex ? subIndex : 0
    },
    clickHeaderMenu() {
      this.isOpenSideMenu = !this.isOpenSideMenu
    },
    closeSideMenu() {
      this.isOpenSideMenu = false
    },
    logout() {
      console.log('ログアウト処理')
    },
    onClickTransition(link: string) {
      window.open(link)
      console.log('ページ遷移')
    },
  },
})
